
export const gallery = (state) => {
    return state.gallery
}






