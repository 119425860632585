import { createStore } from 'vuex'

import gallery from './gallery'

export default createStore({
  modules: {
    gallery

  }
})
